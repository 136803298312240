.poster__content{
	position: relative;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;

	&:hover .poster__content-top{
		transform-origin: left;
    	transform: rotate(-8deg);
		transition: transform 1s;
	}

	img{
		position: absolute;
		width: 35%;
	}
	.poster__content-top{
		z-index: -1;
		transform-origin: left;
		transition: transform 1s;
	}
}