.footer{
	position: fixed;
	width: 100%;
	font-family: $secondaryFont;
	bottom: 40px;
  z-index: 999;

  @include mobile(){
  	display: none;
  }

  .freelance-infos{
  	position: absolute;
    bottom: 60px;
    left: 40px;
    background: $black;
    padding: 14px 20px;
    border-radius: 10px;
    opacity: 0;
    transition: opacity .7s cubic-bezier(.23,1,.32,1);
  }
  textarea{
  	position: absolute;
  	width: 0;
  	height: 0;
  	opacity: 0;
  	bottom: -1000px;
  }

	button{
		position: absolute;
		display: block;
		@include font(18, 32);

		&:hover {
			& + .freelance-infos{
				opacity: 1;
				transition: opacity .7s cubic-bezier(.23,1,.32,1);
			}
			img{
				transform: scale(0.8);
				transition: transform .7s cubic-bezier(.23,1,.32,1);
			}
		}

		img{
			display: inline-flex;
			justify-content: center;
			width: 30px;
			height: 30px;
			margin-left: 4px;
			transition: transform .7s cubic-bezier(.23,1,.32,1);
		}
	}
	&__link-left{
		left: 40px;
		bottom: 0;
	}
	&__link-right{
		right: 40px;
		bottom: 0;
	}
}