@mixin mobile(){
  @media all and (max-width: 767px){
    @content;
  }
}

@mixin tablet-down(){
  @media all and (max-width: 1024px){
    @content;
  }
}

@mixin tablet(){
  @media all and (min-width: 768px) and (max-width: 1280px){
    @content;
  }
}

@mixin tablet-up(){
  @media all and (min-width: 768px){
    @content;
  }
}

@mixin desktop-up(){
  @media all and (min-width: 992px){
    @content;
  }
}

@mixin desktop-down(){
  @media all and (max-width: 992px){
    @content;
  }
}

@mixin media-queries($width){
  @media all and (min-width: ($width + px)) {
   @content;
  }
}

@mixin font($sizeValue: 16, $heightValue: 16){
  font-size: $sizeValue + px;
  font-size: (0.0625 * $sizeValue) + rem;
  line-height: $heightValue + px;
  line-height: (0.0625 * $heightValue) + rem;
}

@mixin stroke($color: #fff, $size: 1px) {
  text-shadow:
    -#{$size} -#{$size} 0 $color,
     0        -#{$size} 0 $color,
     #{$size} -#{$size} 0 $color,
     #{$size}  0        0 $color,
     #{$size}  #{$size} 0 $color,
     0         #{$size} 0 $color,
    -#{$size}  #{$size} 0 $color,
    -#{$size}  0        0 $color;
}