/**
 * HTML5 Boilerplate
 */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}
article, aside, details, figcaption, figure,
h1, h2, h3, h4, h5, h6, footer, header, hgroup, menu, nav, section{display:block;}
table{border-collapse:collapse;border-spacing:0;}
hr{display:block;margin:0;padding:0;height:0;font-size:0;border:0;}
input, select{vertical-align:middle;}
body{margin:0;font:16px sans-serif;*font-size:small;}
select, input, textarea, button{font:99% sans-serif;}
html{box-sizing: border-box;}
a:hover, a:active{outline:none;}
ul, ol{ margin:0; list-style:none; }
ol{list-style-type:decimal;}
nav ul, nav li{margin:0;list-style:none;list-style-image:none;}
strong, th{font-weight:bold;}
sub, sup{font-size:75%;line-height:0;position:relative;}
sup{top:-0.5em;}
sub{bottom:-0.25em;}
textarea{overflow:auto;}
input[type="radio"], input[type="checkbox"]{vertical-align:middle;}
label, input[type="button"], input[type="submit"], input[type="image"], button{cursor:pointer;}
button, input, select, textarea{margin:0;}
input:valid, textarea:valid{}
input:invalid, textarea:invalid{border-radius:1px;-moz-box-shadow:0px 0px 5px red;-webkit-box-shadow:0px 0px 5px red;box-shadow:0px 0px 5px red;}
.no-boxshadow input:invalid, .no-boxshadow textarea:invalid{background-color:#f0dddd;}
::-moz-selection{background:#0a246a;color:#fff;text-shadow:none;}
::selection{background:#0a246a;color:#fff;text-shadow:none;}
a:link{-webkit-tap-highlight-color:#0a246a;}
button{width:auto;overflow:visible;}
.ie7 img{-ms-interpolation-mode:bicubic;}
body, select, input, textarea{color:#000;}
img{ display: block; height:100%;}
iframe{ display: block; max-width:100%; width: 100%; }

input[type="text"], textarea, input[type="submit"], input[type="email"]{ -webkit-appearance:none; -webkit-border-radius:0; }
select{-webkit-appearance:none;-moz-appearance:none;appearance:none;-webkit-border-radius:0;border:0;text-indent:0.01px;text-overflow:"";cursor:pointer}select::-ms-expand{display:none}a{text-decoration:none}
.clearfix:before,.clearfix:after{content:"";display:table;}
.clearfix:after{clear:both;}
.clearfix{*zoom:1;}
*,*:before,*:after{box-sizing: border-box;}