@font-face {
    font-family: 'FivoSans';
    font-weight: 500;
    src: url(fonts/FivoSans-Regular.otf) format("truetype");
}

@font-face {
    font-family: 'ObjectSans';
    font-weight: 900;
    src: url(fonts/ObjectSans-Heavy.otf) format("truetype");
}

@font-face {
    font-family: 'ObjectSans';
    font-weight: 400;
    src: url(fonts/ObjectSans-Regular.otf) format("truetype");
}