.about{
	overflow-x: hidden;

	.presentation{
		position: relative;
		height: 100%;
		width: 100%;

		&::after{
			content: '';
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 40px;
			background: url('./images/dechirure.svg');
		}

		h1{
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100vh;
			height: calc(var(--vh, 1vh) * 100);
			font-family: $primaryFont;
			font-weight: 900;
			max-width: 600px;
			@include font(60, 76);
			left: 20%;
			z-index: 100;

			@include mobile(){
				@include font(40, 60);
				align-items: center;
				left: 0;
				width: 100%;
			}
		}
		canvas{
			position: absolute;
			right: 15%;
			height: 120vh;
			z-index: 10;

			@include mobile(){
		    width: 100%;
		    right: 0;
		    height: 100%;
			}
		}
	}

	.about-me{
		position: relative;
		background: $white;

		&__grid{
			position: relative;
			margin: auto;
	    width: 75vw;
	    padding: 5.3125vw 0 5.0625vw;

	    .g-d, .g-s{
	    	float: left;
	    }
	    .g-d{
	    	width: 46.6666667vw;
	    	padding-bottom: 200px;
	    	@include tablet-down(){
	    		width: 100%;
	    		padding-bottom: 0;
	    	}
	    }
	    .g-s{
	    	width: 23.3333333vw;
	    	padding: 0 0 0 10vw;
	    	@include tablet-down(){
	    		width: 100%;
	    		padding: 100px 0;
	    	}
	    }
		}
		h2{
			display: block;
			font-family: $primaryFont;
			color: $black;
			@include font(42, 42);
			font-weight: 900;
		}
		p, ul{
			display: block;
			font-family: $secondaryFont;
			@include font(20, 32);
			color: #595959;
			margin-top: 48px;
		}
	}

	.a-container{
		margin-top: 120px;
		cursor: grab;
		padding: 100px 0;
		// max-height: 440px;

		img{
			height: 100%;
			width: auto;
			margin: 0 auto;
			@include mobile(){
				width: 100%;
			}
		}

		.swiper-slide{
			// height: 440px;
			// width: 1165px !important;

			// img{ margin: initial; }
			// max-width: 1165px;
		}
	}

	.lets-work{
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		h3{
			display: block;
			@include font(60, 76);
			font-family: $primaryFont;
			font-weight: 900;
			color: $white;
			margin-bottom: 40px;
    	margin-top: 180px;
    	padding: 0 40px;
    	text-align: center;

			span{ color: $yellow; }

			@include mobile(){
				margin-top: 100px;
			}
		}

		
		.social-links{ 
			display: inline-flex;
			@include mobile(){ display: block; text-align: center; } 

			a{
				font-family: $secondaryFont;
				color: rgba(255, 255, 255, 0.7);
				@include font(20, 20);
				margin-right: 80px;
				&:hover{ color: rgba(255, 255, 255, 1); }
				&:last-child{
					margin-right: 0;
				}

				@include mobile(){
					display: block;
					margin-right: 0;
					margin-top: 40px;
				}
			}
		}

		.et-merce{
			display: block;
			@include font(20, 32);
			font-family: $secondaryFont;
			color: rgba(255, 255, 255, 0.7);
			padding: 0 60px;
			text-align: center;
			margin-top: 120px;
    	margin-bottom: 120px;
    	@include mobile(){ margin-top: 100px; }

    	a{ 
    		color: $yellow;
    		font-weight: 700;

    		&:hover{
	    		cursor: url('https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/232/i-love-you-hand-sign_1f91f.png'), auto;
	    		transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1), -webkit-transform 0.4s cubic-bezier(0.7, 0, 0.3, 1);
	    	}
    	}
		}
	}
}