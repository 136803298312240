.r-container{
	left: -15%;
	transform: rotate(-30deg);
	// transform-origin: top right;

	img{
		height: 100%;
		width: auto;
		margin: 0 auto;
		// height: 620px;
		// width: 440px;
	}

	.swiper-wrapper{
		-webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
	}

	.swiper-slide-active{
		.s-b{ z-index: 999; }
		.s-b:hover{
			& + .s-t{
				transform: rotate(-10deg);
				transition: transform 0.4s cubic-bezier(.7,0,.3,1);
			}
		}
	}

	.swiper-slide{
  	opacity: 0.3;

  	a{
  		display: flex;
  		justify-content: center;

  		img{
  			position: absolute;
  			transform-origin: left;
  			transition: transform 0.4s cubic-bezier(.7,0,.3,1);
  		}
  	}
  }
}

.swiper-container{
	width: 100%;
	height: 100%;

	.swiper-wrapper {
	  // align-items: center;
	}
}

.s-c{
	position: absolute;
	display: flex;
	justify-content: center;
	flex-direction: column;
	top: calc(50% - 117px);
  left: 42%;
	text-align: left;
	z-index: 999;

	@include mobile(){
		width: 100%;
    left: 0;
    height: auto;
    top: auto;
    bottom: 10%;
    -webkit-box-align: center;
    align-items: center;
    padding: 20px 0;
	}

	&__t, &__d{ display: block; }
	&__t{
		@include font(94, 94);
		color: $white;
		font-family: $primaryFont;
		font-weight: 900;
		max-width: 564px;
		margin-bottom: 20px;
		@include mobile(){
			width: auto;
			text-align: center;
			@include font(46, 46);
		}
	}
	&__d{
		@include font(26, 26);
		color: #D0D1D2;
		font-family: $secondaryFont;
		@include mobile(){
			@include font(14, 14);
		}
	}
}

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
	bottom: -40px !important;
}
.swiper-pagination-bullet-active{
	background: $bg !important;
}

.scroll{
	position: absolute;
	font-family: $primaryFont;
	@include font(14, 14);
	color: #fff;
	width: 100%;
  bottom: 0;
  text-align: center;
	display: none;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: 99;

	@include mobile(){
		display: flex;
	}

	p{
		display: block;
		margin-bottom: 25px;
	}

	.line__container{
		overflow: hidden;
		height: 24px;

		&-item{
			position: relative;
			background: #fff;
			width: 1px;
			height: 24px;
			animation: scrollMove 1700ms cubic-bezier(.58,.8,.46,.93) infinite forwards ;
			margin: auto 1em;
		}
	}
	@keyframes scrollMove {
	  0%, 10% {
	    transform: translatey(-50px);
	  }
	  50% {
	    transform: translatey(0px);
	  }
	  100% {
	    transform: translatey(50px);
	    
	  }
	}
}

.slider-h{
  .scrollable {
    height: 665px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    @include mobile(){
    	padding: 0 40px;
    }
  }
  .scrollable-container {
    display: flex;
    align-items: center;
    &:first-child{
    	margin-left: 50vw;
    }
    @include mobile(){
    	display: block;
    	margin-left: 0 !important;
    }
  }
  .swiper-slide:nth-child(2){
  	@include mobile(){
  		margin-top: 75px;
  	}
  }
  .js & {
    .scrollable {
      overflow: hidden;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }
  }
}