// Minimal Grid Settings
$gutter: 35px;

$from-tiny: 414px;
$to-tiny: $from-tiny - 1;
$from-small: 768px;
$to-small: $from-small - 1;
$from-medium: 1024px;
$to-medium: $from-medium - 1;
$from-large: 1280px;
$to-large: $from-large - 1;
$from-huge: 1680px;
$to-huge: $from-huge - 1;

// Minimal Grid Tools
@function span($fraction) {
	@return $fraction * 100%;
}

// Minimal Grid Objects 
@mixin grid {
	letter-spacing: normal;
	font-size: 0;
	max-width: 1360px;
	width: 100%;
	margin: 0 auto;
	padding: 0 40px;
}
.grid {
	@include grid;	
}

@mixin grid__item {
	display: inline-block;
	box-sizing: border-box;
	width: 100%;
	vertical-align: top;
	margin-bottom: 40px;
}

@mixin whole {
	@include grid__item;

	width: 100%;
}

@mixin half {
	@include grid__item;

	@media (min-width: $from-small) {
		width: span(1/2);
	}
}

@mixin third {
	@include grid__item;

	@media (min-width: $from-medium) {
		width: span(1/3);
	}
}

@mixin two-thirds {
	@include grid__item;

	@media (min-width: $from-medium) {
		width: span(2/3);
	}
}

@mixin quarter {
	@include grid__item;

	@media (min-width: $from-medium) {
		width: span(1/4);
	}
}

@mixin two-quarters {
	@include grid__item;

	@media (min-width: $from-medium) {
		width: span(2/4);
	}
}

@mixin three-quarters {
	@include grid__item;

	@media (min-width: $from-medium) {
		width: span(3/4);
	}
}

@mixin fifth {
	@include grid__item;

	@media (min-width: $from-large) {
		width: span(1/5);
	}
}

@mixin two-fifths {
	@include grid__item;

	@media (min-width: $from-large) {
		width: span(2/5);
	}
}

@mixin three-fifths {
	@include grid__item;

	@media (min-width: $from-large) {
		width: span(3/5);
	}
}

@mixin four-fifths {
	@include grid__item;

	@media (min-width: $from-large) {
		width: span(4/5);
	}
}

@mixin sixth {
	@include grid__item;

	@media (min-width: $from-huge) {
		width: span(1/6);
	}
}

@mixin two-sixths {
	@include grid__item;

	@media (min-width: $from-huge) {
		width: span(2/6);
	}
}

@mixin three-sixths {
	@include grid__item;

	@media (min-width: $from-huge) {
		width: span(3/6);
	}
}

@mixin four-sixths {
	@include grid__item;

	@media (min-width: $from-huge) {
		width: span(4/6);
	}
}

@mixin five-sixths {
	@include grid__item;

	@media (min-width: $from-huge) {
		width: span(5/6);
	}
}


// Your Components
// Name the classes the way you want for your components and include the Minimal Grid mixins.
.whole {
	@include whole;
}

.half {
	@include half;
}

.third {
	@include third;
}

.two-thirds {
	@include two-thirds;
}

.quarter {
	@include quarter;
	@include tablet(){
		width: 100% !important;
	}
}

.two-quarters {
	@include two-quarters
}

.three-quarters {
	@include three-quarters;
}

.fifth {
	@include fifth;
}

.two-fifths {
	@include two-fifths;
}

.three-fifths {
	@include three-fifths;
}

.four-fifths {
	@include four-fifths;
}

.sixth {
	@include sixth;
}

.two-sixths {
	@include two-sixths
}

.three-sixths {
	@include three-sixths
}

.four-sixths {
	@include four-sixths;
}

.five-sixths {
	@include five-sixths;
}

.custom {
	@include grid__item;
	
	width: 15%;
}