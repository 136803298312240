.project{
	background: #fff;

	.poster-torn{
		position: relative;
    width: auto;
    display: flex;
    height: 80vh;
    height: calc(var(--vh, 1vh) * 80);
    z-index: -1;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    
    @include mobile(){
    	height: 40vh !important;
    	height: calc(var(--vh, 1vh) * 40) !important;
    }

	  &::after{
			content: "";
	    position: absolute;
	    bottom: 0;
	    width: 100%;
	    height: 40px;
	    background: url(/images/dechirure.svg);
		}
		&__transition{
			object-fit: cover;
	    height: 100%;
	    // margin-top: -10%;
	    width: 100%;
	    @include mobile(){
	    	height: 120%;
	    }
		}
		&__item{
			display: flex;
	    position: absolute;
	    -webkit-box-align: center;
	    align-items: center;
	    -webkit-box-pack: center;
	    justify-content: center;
	    height: 60%;
	    bottom: 0;
	    width: auto;
	    @include mobile(){ height: 50%; }
		}
	}
	&__container{
		position: relative;
		z-index: 99;
		width: 100%;
		background: #fff;

		.project-infos{
			position: relative;
			display: flex;
			width: 100%;
			margin: auto;
			padding: 150px 6vw;
			color: #000;
			@include mobile(){
				flex-direction: column;
				padding: 40px;
			}

			&__left, &__right{ @include mobile(){ width: 100% !important; } }

			&__left{
				width: 50%;

				h2{
					font-family: $primaryFont;
					font-weight: 900;
					padding-right: 50px;
					@include font(60, 72);
					margin-bottom: 10px;
					@include mobile(){
						@include font(40, 52);
					}
				}
				h4{
					@include font(28, 28);
					margin-bottom: 68px;
					@include mobile(){
						@include font(20, 20);
					}
				}
			}
			&__right{
				width: 50%;
			}
			h2, h4, p{ display: block; }
			h4, p{ font-family: $secondaryFont; }
			p{ @include font(20, 32); color: rgba(0, 0, 0, 0.65); }
		}

		.project-focus{
			position: relative;
			background: #F7F7F7;
			height: 665px;

			h4{
				position: absolute;
				display: flex;
				font-family: $primaryFont;
				@include font(330, 330);
				color: rgba(0, 0, 0, 0.03);
				font-weight: 900;
				align-items: center;
				justify-content: center;
				height: 100%;
				width: 100%;
				z-index: 1;
			}
			.swiper-slide{
				justify-content: center;
		    display: flex;
		    flex-direction: column;

		    &:nth-child(2){
		    	margin-left: 100px;
		    	@include mobile(){
		    		margin-left: 0;
		    	}
		    }
			}
			.mobile-presentation{
				display: flex;
				max-width: 1280px;
				width: 100%;
				margin: 0 auto;
				justify-content: center;

				img{
					display: flex;
					width: auto;
					height: fit-content;

					&:first-child{
						margin-top: -100px;
						@include mobile(){ margin-top: 0; }
					}
					&:nth-child(2){
						padding: 0 80px;
						@include mobile(){
							padding: 0;
							margin: 0 -150px;
						}
					}
					&:nth-child(3){
						margin-top: 100px;
						@include mobile(){ margin-top: 0; }
					}
				}
			}
			&.f-screen{
				display: flex;
				justify-content: center;
				flex-direction: column;
				height: 378px;
				margin-top: 100px;

				.f-screen__container{
					max-width: 1280px;
					width: 100%;
					margin: 0 auto;
					padding: 0 40px;
				}
				p{
					max-width: 800px;
					width: 100%;
					@include font(20, 32);
					color: rgba(0, 0, 0, 0.65);
				}
			}
			h5, p{ 
				display: flex;
				color: #000;
				align-items: center;
			}
			h5{
				font-family: $primaryFont;
				@include font(42, 42);
				font-weight: 900;
				margin-bottom: 12px;
				@include mobile(){
					@include font(32, 32);
				}
			}
			p{
				font-family: $secondaryFont;
				@include font(20, 32);
				max-width: 600px;
				@include mobile(){
					@include font(20, 32);
				}
			}
		}
		.w-50{
			display: flex;
			width: 100%;
			height: 100%;
			@include mobile(){
				display: block;
			}

			img{
				position: absolute;
				left: -10%;
				width: 54%;
				@include mobile(){
					position: initial;
					width: 100%;
					left: auto;
				}
				@media all and (min-width: 1920px){
					margin-top: 100px !important;
					width: 50%;
				}
			}

			&__item{
				width: 50%;
				@include mobile(){
					width: 100%;
				}
			}
		}
		.project-details{
			max-width: 1280px;
			width: 100%;
			margin: 0 auto;
			color: #000;
			padding: 0 40px;

			h3{
				display: block;
				font-family: $primaryFont;
				font-weight: 900;
				@include font(42, 42);
				margin-top: 256px;
				@include mobile(){
					margin-top: 150px;
					@include font(32, 32);
				}
			}
			p{
				display: block;
				font-family: $secondaryFont;
				@include font(20, 32);
				color: rgba(0, 0, 0, 0.65);
				margin-top: 40px;
				@include mobile(){
					margin-top: 50px;
				}
			}
			img, video{
				margin-top: 225px;
			}
		}
		.project-next{
			position: relative;
			display: block;
			margin-top: 250px;
			height: 100%;

			&:hover{
				img{
					transform: scaleY(1.1);
					transition: transform 0.5s;
				}
			}
			&__title{
		    position: absolute;
		    display: flex;
				width: 100%;
				justify-content: center;
		    align-items: left;
		    flex-direction: column;
		    height: 100%;
		    top: 0;
		    padding: 0 5vw;
		    @include mobile(){
		    	align-items: center;
		    	text-align: center;
		    }
			}
			h4{
				font-family: $primaryFont;
				font-weight: 900;
				margin-top: 40px;
				color: $white;
				@include font(42, 42);
			}
			p{
				display: block;
				font-family: $secondaryFont;
				@include font(28, 28);
				margin-top: 20px;
				@include mobile(){
					@include font(20, 20);
					margin-top: 10px;
				}
			}
			img{
				object-fit: cover;
				object-position: top;
    		height: 400px;
    		transition: transform 0.5s;
    		transform: scaleY(1);
			}
		}
	}
}

.tP-disney{ 
	@include mobile(){ overflow-x: hidden !important; }
	.poster-torn__item{ 
		bottom: -80px; 
		height: 80%;
		@include mobile(){ bottom: -30px; }
	} 
}
.tP-anonymous{ 
	@include mobile(){ overflow-x: hidden !important; }
	.poster-torn__item{ 
		height: 80%;
		bottom: -200px; 
		@include mobile(){ bottom: -50px; }
	} 
	.project-details img{
		margin-top: 50px;
	}
}
.tP-ca{ 
	@include mobile(){ overflow-x: hidden !important; }
	.poster-torn__item{ 
	  height: 50%;
    bottom: 140px; 
    @include mobile(){ bottom: 70px; }
  } 
  .project-details img{
		margin-top: 50px;
	}
}
.tP-ecv{ 
	@include mobile(){ overflow-x: hidden !important; }
	.poster-torn__item{ 
	  height: 100%;
	  bottom: -250px;
	  @include mobile(){ bottom: -75px; }
  }
  .project-details img{
		margin-top: 50px;
	}
	.project-infos__left h2{
		white-space: nowrap;
		@include font(30, 42);
	}
}