.intro{
	position: relative;
	background: $bg;	
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	z-index: 1000;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: wait !important;

	&__preloader{
		position: absolute;
		width: 0%;
		height: 4px;
		top: 0;
		left: 0;
		background: $yellow;
		z-index: 1001;
	}
	video{
		height: 60vh;
		height: calc(var(--vh, 1vh) * 60);
		width: auto;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	}
	&__text{
		display: block;
		font-family: $primaryFont;
		@include font(18, 30);
		margin-top: 40px;
	}
}