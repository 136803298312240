// Default
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}
html, body{
  width: 100%;
  height: 100%;
}
html, body{
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
}
a,body,div,footer,h1,h2,h3,header,html,img,li,nav,p,span,table,ul{margin:0;padding:0;border:0;font:inherit;font-family:inherit;font-size:100%}
li,ul{list-style:none}
a{color:inherit;text-decoration:none}
img{width:100%;height:auto}
button{border: none;margin: 0;padding: 0;width: auto; outline: 0;overflow: visible;background: transparent;color: inherit;font: inherit;line-height: normal;-webkit-appearance: none; &::-moz-focus-inner {border: 0; padding: 0;} }

// End default
body{
  background: $bg;
  color: $white;
}  

.p-fixed{
  position: fixed;
  overflow: hidden;
}

a{
  display: block;

  &:hover{
    .c-l{
      transform: rotate(-10deg);
    }
    .c-r{
      transform: rotate(-10deg);
    }
  }
  span{
    display: inline-block; 
  }
  .c-l{
    transform-origin: bottom left;
    transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1), -webkit-transform 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  }
  .c-r{
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1), -webkit-transform 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  }
}

.link{
  text-decoration: none;
  color: transparent;
  position: relative;
  
  &:before, &:after {
    content: attr(data-text);
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    pointer-events: none;
    transition: transform .7s cubic-bezier(.23,1,.32,1), 
                opacity .7s cubic-bezier(.23,1,.32,1);
  }
  
  &:before {
    transition-delay: 120ms;
  }
  
  &:after {
    transform: translateY(12px);
    opacity: 0;
  }
  
  &:hover {
    &:before {
      transform: translateY(-12px);
      opacity: 0;
    }
    
    &:after {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 240ms;
    }
  }
}

.o-hidden{ overflow: hidden; }