.header{
	position: fixed;
	width: 100%;
	font-family: $primaryFont;
	top: 40px;
	z-index: 999;
	mix-blend-mode: difference;

	a{
		position: absolute;
		color: #fff;
	}
	&__link-left{
		left: 40px;
		@include font(18, 18);
	}
	&__link-right{
		right: 40px;
		@include font(18, 18);
	}
	&__link-left, &__link-right{ @include mobile(){ @include font(16, 16); } }
}