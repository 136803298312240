// .c-item{
// 	position: absolute;
// 	width: 120%;
// 	height: 120%;
// 	z-index: 1000;
// 	bottom: -120%;

// 	img{ height: 100%; }
// }

.c-item__container{
	position: relative;
    height: 100%;
    width: 100%;
}

.c-item{
	position: absolute;
    width: 120%;
    height: 0;
    z-index: 1000;
    object-fit: cover;
    object-position: top;
    bottom: 0;

	img{
	    position: absolute;
		height: 0%;
	    bottom: 0;
	}
}